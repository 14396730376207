const MAX_FETCH_RETRIES = 2
const DELAY_AFTER_FETCH_RETRY = 100
const HTTP_STATUS_OK = 200
const IMMUTABLES_ERROR_CODES = [400, 429, 403, 401] // skip retry for business logic errors

const shouldRetry = (retries, status = -1) =>
  retries > 0 && !IMMUTABLES_ERROR_CODES.includes(status)

const setAuthorizationHeaderContext = (options, ravenInstance) => {
  if (options && options.headers  && options.headers['Authorization']) {
    ravenInstance.setExtraContext({ authorizationHeader: options.headers['Authorization'] })
  }
}

export const fetchRetry = ravenInstance => (
  url?: Request | string,
  options: RequestInit = {},
  disableRetry = false
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    const wrappedFetch = retries => {
      fetch(url, options)
        .then(response => {
          if (response.status === HTTP_STATUS_OK) {
            //tslint:disable-line
            resolve(response)
          } else {
            if (!disableRetry && shouldRetry(retries, response.status)) {
              retry(retries)
            } else {
              const requestId = response.headers.get('x-wix-request-id')
              if (requestId) {
                ravenInstance.setTagsContext({ 'x-wix-request-id': requestId })
              }

              setAuthorizationHeaderContext(options, ravenInstance)

              if (disableRetry) {
                ravenInstance.setTagsContext({ 'retry-disabled': disableRetry })
              }

              const headersObject = {}
              response.headers.forEach((value, key) => {
                headersObject[key] = value
              })

              ravenInstance.setExtraContext(headersObject)

              response
                .json()
                .then(err => {
                  ravenInstance.setExtraContext(err)
                  reject(new Error(`Fetch failed with status ${response.status}`))
                })
                .catch(() => {
                  ravenInstance.setTagsContext({ 'fetch-error-failed': true })
                  reject(new Error(`Fetch failed with status ${response.status}`))
                })
            }
          }
        })
        .catch(error => {
          if (!disableRetry && shouldRetry(retries)) {
            retry(retries)
          } else {
            ravenInstance.setTagsContext({ 'network-error': true })
            setAuthorizationHeaderContext(options, ravenInstance)

            if (disableRetry) {
              ravenInstance.setTagsContext({ 'retry-disabled': disableRetry })
            }

            reject(error)
          }
        })
    }

    const retry = retries => {
      setTimeout(() => {
        wrappedFetch(--retries)
      }, DELAY_AFTER_FETCH_RETRY)
    }

    wrappedFetch(MAX_FETCH_RETRIES)
  })
}
