import { COMPONENT_TYPES } from '../../../constants/component-types'
import { PROPERTIES } from '../../../constants/api-types'
import { getComponentTypeLayoutProp } from '../services/layout-service'

export type FieldLayoutProp = {
  [key: string]: PROPERTIES
} | null

export type FieldPropHandler = (
  componentType: COMPONENT_TYPES,
  value: PROPERTIES
) => FieldLayoutProp | null
export type FieldDataHandler = FieldPropHandler

export const getTextAlignmentProp: FieldPropHandler = (componentType, value) => {
  return getComponentTypeLayoutProp(componentType, 'textAlignment', value)
}

export const getLabelMarginProp: FieldPropHandler = (componentType, value) => {
  return getComponentTypeLayoutProp(componentType, 'labelMargin', value)
}

export const getLabelPaddingProp: FieldPropHandler = (componentType, value) => {
  return getComponentTypeLayoutProp(componentType, 'labelPadding', value)
}

export const getInputTextPaddingProp: FieldPropHandler = (componentType, value) => {
  return getComponentTypeLayoutProp(componentType, 'textPadding', value)
}
