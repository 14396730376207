import * as _ from 'lodash'
import { isProduction, parseInstance } from '../../utils/utils'
import * as axios from 'axios'
import { CustomField } from '../../constants/field-types'
import { FORMS_APP_DEF_ID } from '../../constants'
import { PremiumRestriction as DomainPremiumRestriction } from '../../types/domain-types'
import { PremiumRestriction, Feature } from '../../constants/premium'
import { FormPlugin } from '../../constants/plugins'

export enum Method {
  GET = 'GET',
  POST = 'POST',
}

export const FEATURES = {
  CUSTOM_STEPS: 'custom_steps',
  SUBMISSIONS_PER_MONTH: 'submissions_per_month',
  CUSTOM_FIELDS: 'custom_fields',
  CUSTOMIZABLE_FORMS: 'customizable_forms',
  ACCEPT_PAYMENTS: 'accept_payments_on_form',
  UPLOAD_FIELD: 'file_upload_field',
  DOWNLOAD_FILE: 'file_downloads',
  SIGNATURE_FIELD: 'signature_field',
}

const ASCEND_PRODUCT_ID = '73988963-5f5f-4f61-b6a1-fd004df31b00'
const ASCEND_UNLIMITED_PRODUCT_ID = '1f3d81c2-2743-4402-96a9-66c71d7690b0'

const formsBaseUrl = () =>
  isProduction() ? 'editor.wix.com/_api/form-builder-server' : 'localhost:3001'

const hasQuery = (uri: string) => _.includes(uri, '?')
export const getContactsUrl = () => `https://${formsBaseUrl()}/contacts/v1`

export const FORMS_URL = 'https://editor.wix.com/_api/wix-form-builder-web'
export const PLATFORMIZED_FORMS_URL = `${FORMS_URL}/v1`
const PLATFORMIZED_PAYMENTS_URL = 'https://editor.wix.com/_api/payment-services-web/payments/v2'
export const CONTACTS_URL = 'https://editor.wix.com/_api/wix-contacts-webapp/v1'

export const FEATURES_MANAGER_URL = 'https://editor.wix.com/_api/premium-features-manager/v1'
export const PREMIUM_STORE_URL = 'https://editor.wix.com/_api/store/v1'

const PREDEFINED_LABELS = ['contacts-contacted_me', 'contacts-customers']

export default class RemoteApi {
  private boundEditorSDK
  private experiments
  private ravenInstance

  constructor({ boundEditorSDK, experiments, ravenInstance }) {
    this.boundEditorSDK = boundEditorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
  }

  public getLabels = async (): Promise<any> =>
    this._platformizedRequest({
      url: CONTACTS_URL,
      endpoint: 'metadata/labels',
      method: Method.GET,
    })
      .then(data => {
        return _.map(
          _.filter(
            data.labels,
            label => label.type === 'USER' || _.includes(PREDEFINED_LABELS, label.id)
          ),
          label => ({
            id: label.id,
            name: label.details.name,
          })
        )
      })
      .catch(() => [])

  public getOwnerEmail = async () => this._get(getContactsUrl(), 'get-owner-email')

  public getEmailById = async emailId => this._get(getContactsUrl(), `get-email?emailId=${emailId}`)

  public getEmailsById = async (emailId, secondEmailId) =>
    this._get(getContactsUrl(), `get-emails?emailId=${emailId}&secondEmailId=${secondEmailId}`)

  public createTag = async tagName => this._post(getContactsUrl(), 'create-tag', { tagName })

  public updateTag = async (tagId, newName) =>
    this._post(getContactsUrl(), 'update-tag', { tagId, newName })

  public getCustomFields = async () =>
    this._platformizedRequest({
      url: CONTACTS_URL,
      endpoint: 'metadata/fields',
      method: 'GET',
    })
      .then(data =>
        _.map(
          _.filter(data.fields, field => field.fieldType === 'USER_DEFINED'),
          field => ({
            id: field.id,
            name: field.details.name,
            fieldType: field.details.dataType,
          })
        )
      )
      .catch(() => [])

  public insertEmail = async email => this._post(getContactsUrl(), 'insert-email', { email })

  public createCustomField = async (field: CustomField) =>
    this._post(getContactsUrl(), 'create-custom-field', { field })

  public publishSite = data =>
    this._platformizedRequest({
      url: PLATFORMIZED_FORMS_URL,
      endpoint: 'publish-site',
      method: Method.POST,
      data,
    })

  public editDraft = form =>
    this._platformizedRequest({
      url: PLATFORMIZED_FORMS_URL,
      endpoint: 'edit-draft',
      method: Method.POST,
      data: { form: form },
    }).catch(() => null)

  public getPremiumRestrictions = async (): Promise<{ restrictions: PremiumRestriction }> => {
    const { restrictions } = await this._getOldRestrictions()

    const isExperimentEnabled = this.experiments.enabled(
      'specs.cx.FormBuilderSupportDynamicOffering'
    )

    if (!isExperimentEnabled) {
      return { restrictions }
    }

    const mergedRestrictions = await this._mergeFeaturesWithRestrictions(restrictions)

    return { restrictions: mergedRestrictions }
  }

  private async _mergeFeaturesWithRestrictions(restrictions: PremiumRestriction) {
    const mergedRestrictions: PremiumRestriction = _.merge({}, restrictions)
    const isTopPremium = await this._checkIfUserIsTopPremium()
    if (isTopPremium) {
      mergedRestrictions.isTopPremium = isTopPremium
    }

    const features = await this._getFeatures()
    const isEligibleFeature = (feature: Feature, name) =>
      feature.uniqueName === name && feature.id !== 'DEFAULT'

    const convertRestriction = (limit, threshold) =>
      limit === -1 ? { limit, threshold: null } : { limit, threshold }

    _.forEach(features, feature => {
      const limit = _.get(feature, 'quotaFeature.limit', -1)

      if (isEligibleFeature(feature, FEATURES.CUSTOM_STEPS)) {
        mergedRestrictions.steps = convertRestriction(limit, feature.quotaFeature.limit - 1)
      }

      if (isEligibleFeature(feature, FEATURES.SUBMISSIONS_PER_MONTH)) {
        mergedRestrictions.submissions = convertRestriction(limit, feature.quotaFeature.limit * 0.7)
      }

      if (isEligibleFeature(feature, FEATURES.CUSTOM_FIELDS)) {
        mergedRestrictions.fields = convertRestriction(limit, feature.quotaFeature.limit * 0.7)
      }

      if (isEligibleFeature(feature, FEATURES.CUSTOMIZABLE_FORMS)) {
        mergedRestrictions.forms = convertRestriction(limit, feature.quotaFeature.limit - 2)
      }

      if (isEligibleFeature(feature, FEATURES.ACCEPT_PAYMENTS)) {
        mergedRestrictions.allowedPlugins[FormPlugin.PAYMENT_FORM] = true
      }

      if (isEligibleFeature(feature, FEATURES.UPLOAD_FIELD)) {
        mergedRestrictions.allowedFields.uploadButton = true
        mergedRestrictions.allowedFields.generalUploadButton = true
      }

      if (isEligibleFeature(feature, FEATURES.DOWNLOAD_FILE)) {
        mergedRestrictions.allowedRedirections.downloadFile = true
      }

      if (isEligibleFeature(feature, FEATURES.SIGNATURE_FIELD)) {
        mergedRestrictions.allowedFields.generalSignature = true
      }
    })

    return mergedRestrictions
  }

  private async _checkIfUserIsTopPremium(): Promise<boolean> {
    const msid = await this.boundEditorSDK.info.getMetaSiteId()
    const endpoint = `offering/${ASCEND_PRODUCT_ID}?msid=${msid}`
    let currentSubscriptionInfo

    try {
      const request = await this._platformizedRequest({
        url: PREMIUM_STORE_URL,
        endpoint,
        method: Method.GET,
      })
      currentSubscriptionInfo = _.get(request, 'currentSubscriptionInfo')
    } catch {
      currentSubscriptionInfo = {}
    }

    return _.get(currentSubscriptionInfo, 'productId') === ASCEND_UNLIMITED_PRODUCT_ID
  }

  public getRestrictedKeywords = () => this._get(getContactsUrl(), 'keywords')

  public getConnectedPayments = async () => {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    const instanceId = parseInstance(appInstance).instanceId
    const endpoint = `accounts/${FORMS_APP_DEF_ID}:${instanceId}/payment-methods`

    return this._platformizedRequest({
      url: PLATFORMIZED_PAYMENTS_URL,
      endpoint,
      method: Method.GET,
    })
  }

  private _getOldRestrictions = () =>
    this._platformizedRequest({
      url: PLATFORMIZED_FORMS_URL,
      endpoint: 'premium/restrictions',
      method: Method.GET,
    }).then((restrictions: DomainPremiumRestriction): { restrictions: PremiumRestriction } => {
      const convertRestriction = restriction =>
        _.get(restriction, 'unlimited')
          ? { limit: -1, threshold: null }
          : _.get(restriction, 'limited')
      const allowedFeatures = <any>restrictions.allowedFeatures
      return {
        restrictions: {
          steps: convertRestriction(restrictions.steps),
          forms: convertRestriction(restrictions.forms),
          submissions: convertRestriction(restrictions.submissions),
          fields: convertRestriction(restrictions.fields),
          isTopPremium: restrictions.isTopPremium,
          allowedFields: {
            generalUploadButton: _.includes(allowedFeatures, 'GENERAL_UPLOAD_BUTTON'),
            generalSignature: _.includes(allowedFeatures, 'SIGNATURE_FIELD'),
          },
          allowedRedirections: {
            downloadFile: _.includes(allowedFeatures, 'DOWNLOAD_FILE'),
          },
          allowedPlugins: {
            [FormPlugin.PAYMENT_FORM]: _.includes(allowedFeatures, 'PAYMENT_FORM'),
          },
        },
      }
    })

  private _getFeatures = async (): Promise<Feature[]> => {
    const supportedFeatures = _.chain(FEATURES)
      .values()
      .map(value => `uniqueNames=${value}`)
      .join('&')
      .value()
    const endpoint = `bulk-features?${supportedFeatures}`
    let features

    try {
      const request = await this._platformizedRequest({
        url: FEATURES_MANAGER_URL,
        endpoint,
        method: Method.GET,
      })
      features = _.get(request, 'features')
    } catch {
      features = []
    }

    return features
  }

  private _get = (url, endpoint) => this._request({ url, endpoint, method: Method.GET })
  private _post = (url, endpoint, data) =>
    this._request({ url, endpoint, method: Method.POST, data })

  private async _platformizedRequest({ url, endpoint, method, data = undefined }) {
    this.ravenInstance.setExtraContext({ url, endpoint, method, data })

    const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())

    if (isTemplate) {
      return Promise.resolve()
    }

    const appInstance = await this.boundEditorSDK.info.getAppInstance()

    const response = await axios.request({
      headers: { Authorization: appInstance },
      method,
      url: `${url}/${endpoint}`,
      data,
    })

    return _.get(response, 'data')
  }

  private async _request({ url, endpoint, method, data = undefined }) {
    this.ravenInstance.setExtraContext({ url, endpoint, method, data })

    const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    const response = !isTemplate
      ? await axios.request({
          headers: { 'X-Wix-With-Static-Templates': 'true' },
          method,
          url: `${url}/${endpoint}${hasQuery(endpoint) ? '&' : '?'}instance=${appInstance}`,
          data,
        })
      : null

    return _.get(response, 'data')
  }
}
