import { getStaticsBaseUrl, getTranslationByPlugin } from '../../../utils/utils'
import translations from '../../../utils/translations'
import { GFPP_ACTIONS } from '../manifests/manifest-commons'
import { PanelName } from '../manage-panels/consts/panel-names'
import { FormPlugin } from '../../../constants/plugins'
import * as _ from 'lodash'
import { getActivePlugin } from '../plugins/utils'
import MULTI_STEP_HELP_IDS from '../../../plugins/multi-step-form/constants/help-ids'

const createUrlFor = msid => panelName =>
  `https://${getStaticsBaseUrl()}/${panelName}-panel.html?msid=${msid}`

const isMultiStepPlugin = initialData => {
  const plugins = _.get(initialData, 'config.plugins')
  return _.includes(plugins, FormPlugin.MULTI_STEP_FORM)
}

export const createPanelDefs = msid => {
  const urlFor = createUrlFor(msid)

  return {
    [PanelName.ADD_FORM]: ({ experiments, isResponsive }) => {
      const height = isResponsive ? 313 : experiments.enabled('specs.cx.FormBuilderAddNewFormPanel') ? 563 : 508
      const width = isResponsive ? 590 : 666

      return {
        title: translations.t('addForm.panelTitle'),
        width,
        height,
        helpId: 'e5d2c2d0-8f70-4140-b6ef-613327dbe3a8',
        url: urlFor('add-form'),
      }
    },
    [PanelName.FORM_MANAGE_SUBSCRIBERS]: () => ({
      shouldHideHeader: true,
      width: 744,
      height: 488,
      url: urlFor('manage-subscribers'),
    }),
    [PanelName.FIRST_TIME]: () => ({
      shouldHideHeader: true,
      width: '300',
      height: '364',
      url: urlFor('first-time'),
    }),
    [PanelName.FORM_SETTINGS]: (componentRef, initialData) => ({
      title: translations.t('settings.panelTitle'),
      componentRef,
      initialData,
      width: 288,
      height: 546,
      type: GFPP_ACTIONS.SETTINGS,
      helpId: '82b1e9b4-4e92-42c9-b414-2e4a0200e97f',
      url: urlFor('settings'),
    }),
    [PanelName.NEW_FORM_SETTINGS]: (componentRef, initialData) => ({
      title: getTranslationByPlugin({
        t: translations.t,
        prefix: 'formSettings',
        postfix: 'panelTitle',
        plugin: getActivePlugin(_.get(initialData, 'config.plugins')),
      }),
      componentRef,
      initialData,
      width: 437,
      height: 582,
      type: GFPP_ACTIONS.SETTINGS,
      helpId: isMultiStepPlugin(initialData)
        ? MULTI_STEP_HELP_IDS.SETTINGS
        : '342ee9a5-b635-4669-bca7-6bfe756c8ab0',
      url: urlFor('form-settings'),
    }),
    [PanelName.FORM_MANAGE_FIELDS]: (componentRef, initialData, { mode }) => ({
      title: translations.t('manageFields.panelTitle'),
      componentRef,
      initialData,
      width: 288,
      height: 546,
      helpId: {
        desktop: '357699f6-91ef-481a-9daa-14b5f029239c',
        mobile: 'f6d126a0-108c-4157-98f7-74dac3ac067e',
      }[mode],
      url: urlFor('manage-fields'),
    }),
    [PanelName.MANAGE_STEPS]: (componentRef, initialData) => ({
      title: translations.t('multiStepForm.manageSteps.panelTitle'),
      componentRef,
      initialData,
      width: 288,
      height: 546,
      url: urlFor('manage-steps'),
      helpId: MULTI_STEP_HELP_IDS.MANAGE_STEPS,
    }),
    [PanelName.CHANGE_BUTTON_LABEL]: (componentRef, initialData) => ({
      title: translations.t(`changeButtonLabelPanel.${_.camelCase(initialData.role)}.title`),
      componentRef,
      initialData,
      width: 288,
      height: 151,
      url: urlFor('change-button-label'),
      helpId: MULTI_STEP_HELP_IDS.MANAGE_STEPS,
    }),
    [PanelName.FIELD_SETTINGS]: (componentRef, initialData, { mode, experiments }) => {
      const shouldOpenConnectFieldPanel = experiments.enabled('specs.cx.FormBuilderConnectGfpp')
      return {
        title: shouldOpenConnectFieldPanel
          ? translations.t('connectFieldPanel.panelTitle')
          : translations.t('fieldSettings.panelTitle'),
        componentRef,
        initialData,
        width: 288,
        height: shouldOpenConnectFieldPanel ? 300 : 440,
        helpId: {
          desktop: shouldOpenConnectFieldPanel
            ? 'be46ba62-113a-4fba-9805-fc251858005b'
            : '9daa29ec-1cda-460e-a855-4a43858af5fb',
          mobile: 'dad84a79-8701-408c-9174-454062221932',
        }[mode],
        url: urlFor('field-settings'),
      }
    },
    [PanelName.FORM_LAYOUT]: (componentRef, initialData, { mode, isResponsive }) => ({
      title: translations.t('formStyle.layout.panelTitle'),
      componentRef,
      initialData,
      width: 288,
      height: isResponsive ? 203 : 546,
      type: GFPP_ACTIONS.LAYOUT,
      helpId: {
        desktop: isMultiStepPlugin(initialData)
          ? MULTI_STEP_HELP_IDS.LAYOUTS
          : '69c55c8d-6865-45ed-ab87-7ce70e1564f9',
        mobile: isMultiStepPlugin(initialData)
          ? MULTI_STEP_HELP_IDS.LAYOUTS
          : '7a4ec10c-f40f-4ebf-a475-0fbdd3978ad6',
      }[mode],
      url: urlFor('form-layout'),
    }),
    [PanelName.SUBMIT_SETTINGS]: (componentRef, initialData) => {
      const isRegistrationForm = _.includes(
        initialData.config.plugins,
        FormPlugin.REGISTRATION_FORM,
      )
      const showEnhancedSubmitSettingsPanel = !isRegistrationForm
      return {
        title: showEnhancedSubmitSettingsPanel
          ? translations.t('submitSettingsPanel.formBuilder.panelTitle')
          : translations.t('buttonChangeText.panelTitle'),
        componentRef,
        initialData,
        width: 288,
        height: showEnhancedSubmitSettingsPanel ? 525 : 176,
        url: urlFor('submit-settings'),
        helpId: '949303cc-13bf-4d95-ba5c-72d2db07b69e',
      }
    },
    [PanelName.ADD_FIELD]: (componentRef, initialData) => ({
      title: translations.t('addField.panelTitle'),
      initialData,
      componentRef,
      width: 430,
      height: 519,
      type: GFPP_ACTIONS.ADD,
      url: urlFor('add-field'),
      helpId: '4681150c-affb-4fbf-a7a9-26fd45557cbf',
    }),
    [PanelName.UPGRADE_ALERT]: (componentRef, initialData) => ({
      shouldHideHeader: true,
      initialData,
      componentRef,
      width: 564,
      height: 306,
      url: urlFor('upgrade-alert'),
    }),
    [PanelName.PUBLISH_SITE_POP_UP]: componentRef => ({
      title: translations.t('publishSite.panelTitle'),
      initialData: {
        componentRef,
      },
      width: 500,
      height: 247,
      url: urlFor('publish-site'),
    }),
    [PanelName.PAYMENT_WIZARD]: initialData => ({
      shouldHideHeader: true,
      initialData,
      width: 614,
      height: 396,
      url: urlFor('payment-wizard'),
    }),
    [PanelName.DELETE_PAYMENT_ALERT]: (componentRef, initialData) => ({
      shouldHideHeader: true,
      componentRef,
      initialData,
      width: 500,
      height: 247,
      url: urlFor('delete-payment-alert'),
    }),
    [PanelName.CLOSE_PAYMENT_WIZARD_ALERT]: (componentRef, initialData) => ({
      shouldHideHeader: true,
      componentRef,
      initialData,
      width: 500,
      height: 247,
      url: urlFor('close-payment-wizard-alert'),
    }),
    [PanelName.RENAME_FORM_PANEL]: (componentRef, initialData) => ({
      title: translations.t('formSettings.renameFormPanel.header'),
      helpId: 'd1de5e4f-9ecf-4b3a-bda6-fb1edbc53852',
      componentRef,
      initialData,
      width: 437,
      height: 217,
      url: urlFor('rename-form'),
    }),
    [PanelName.CONTACT_SYNC_PANEL]: (componentRef, initialData) => ({
      title: translations.t('contactSync.panelTitle'),
      componentRef,
      initialData,
      width: 504,
      height: 753,
      url: urlFor('contact-sync'),
    }),
  }
}
