export enum PanelName {
  ADD_FORM = 'addFormPanel',
  FORM_MANAGE_FIELDS = 'manageFieldsPanel',
  FORM_SETTINGS = 'settingsPanel',
  NEW_FORM_SETTINGS = 'formSettingsPanel',
  FORM_STYLE = 'formStylePanel',
  FORM_LAYOUT = 'formLayoutPanel',
  FIELD_SETTINGS = 'fieldSettingsPanel',
  SUBMIT_SETTINGS = 'submitSettingsPanel',
  ADD_FIELD = 'addFieldPanel',
  UPGRADE_ALERT = 'upgradeAlertPanel',
  FORM_MANAGE_SUBSCRIBERS = 'manageSubscribersPanel',
  FIRST_TIME = 'firstTimePanel',
  SITE_MEMBERS_SETTINGS = 'panels.focusPanels.siteMembersSettingsPanel',
  CONNECT_FIELD = 'connectFieldPanel',
  RECAPTCHA_HELP = 'recaptchaHelp',
  PUBLISH_SITE_POP_UP = 'publishSitePop',
  PAYMENT_WIZARD = 'paymentWizardPanel',
  DELETE_PAYMENT_ALERT = 'deletePaymentAlert',
  CLOSE_PAYMENT_WIZARD_ALERT = 'closePaymentWizardAlert',
  ADI_EDIT_FORM = 'adiEditFormPanel',
  ADI_EDIT_FIELD = 'adiEditFieldPanel',
  MANAGE_STEPS = 'manageSteps',
  CHANGE_BUTTON_LABEL = 'changeButtonLabel',
  RENAME_FORM_PANEL= 'renameFormPanel',
  CONTACT_SYNC_PANEL = 'contactSyncPanel',
}
