import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { generalSingleCheckboxData } from '../../general-fields/definitions/general-single-checkbox'
import { FieldRenderConfigType } from '../../constants'
import { FormPlugin } from '../../../../../../constants/plugins'

const makeAgreeTerms = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalSingleCheckboxData.make(), {
    extraData: {
      role: FIELDS.ROLE_FIELD_AGREE_TERMS,
      connectionConfig: {
        crmLabel: t(`fieldTypes.${FormsFieldPreset.AGREE_TERMS}`),
      },
      data: {
        label: t(`preset.${FormsFieldPreset.AGREE_TERMS}Label`),
      },
      props: {
        required: true,
      },
      layout: {
        width: 290,
      },
    },
  })
}

export const agreeTermsData: FieldData = {
  make: makeAgreeTerms,
  customFields: generalSingleCheckboxData.customFields,
  icon: generalSingleCheckboxData.icon,
  renderConfig: {
    [FormPlugin.FORM_BUILDER]: {
      required: FieldRenderConfigType.DISABLED,
      internalName: FieldRenderConfigType.DISABLED,
      checkedByDefault: FieldRenderConfigType.DISABLED,
    }
  },
  allowOnlyOnce: true,
}
